<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Terminal</span>
                <span class="headline" v-else>Create New Terminal</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Terminal ID*" :readonly="formType === 'UPDATE'" v-model="form.terminalId" required :rules="[rules.requiredField]" data-cy="form-terminal-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Serial Number*" v-model="form.serialNum" required :rules="[rules.requiredField]" data-cy="form-serial-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Model*" v-model="form.model" required :rules="[rules.requiredField]" data-cy="form-model"></v-text-field>
                            </v-flex>
                             <v-flex xs12 >
                                 <v-select :items="typeOfPosList" v-model="form.type" label="Type of POS" @change="getTypeOfPos" data-cy="form-type"></v-select>
                            </v-flex> 
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="merchantList" item-text="name" item-value="name" label="Merchant" v-model="form.merchant" return-object data-cy="form-merchant">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="branchList" item-text="name" item-value="code" label="Branch" v-model="form.branch" return-object data-cy="form-branch">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="deviceTypeList" item-text="name" item-value="code" label="Device Type" v-model="form.deviceType" return-object data-cy="form-device-type">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 v-if="isNotPaymentAgent">
                                <v-autocomplete clearable chips :items="paymentAgentList" item-text="name" item-value="code" label="Payment Agent" v-model="form.paymentAgent" return-object data-cy="form-payment-agent">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="userList" item-text="username" item-value="username" label="User" v-model="form.user" return-object data-cy="form-username">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Network" v-model="form.network" data-cy="form-network"></v-text-field>
                            </v-flex>
                            <v-flex xs12 >
                                 <v-select :items="bancnetLocationList" v-model="form.location" label="Location" @change="getLocation" data-cy="form-location"></v-select>
                            </v-flex>   
                            <v-flex xs12 >
                                 <v-select :items="pandateEntryModeList" v-model="form.pandateEntryMode" label="Pan/Date Entry Mode" @change="getPandateEntryMode" data-cy="form-pandate-entrymode"></v-select>
                            </v-flex>    
                            <v-flex xs12 >
                                 <v-select :items="currencyList" v-model="form.currency" label="Currency" @change="getCurrency" data-cy="form-currency"></v-select>
                            </v-flex>    
                            <v-flex xs12>
                                 <v-select :items="posterminalTypeList" v-model="form.posterminalType" label="POS Terminal Type" @change="getPosTerminalType" data-cy="form-posterminal-type"></v-select>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Bancnet Address" v-model="form.bancnetAddress" data-cy="form-bancnet-address"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Bancnet Merchant Settlement Bank Code" v-model="form.bancnetMerchantSettlementBankCode" data-cy="form-bancnet-merchantsettlement-bank-code"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <!-- <v-text-field label="Bancnet Terminal Owner" v-model="form.bancnetTerminalOwner" data-cy="form-bancnet-terminalowner"> </v-text-field> -->
                                <v-text-field label="Bancnet Merchant ID" v-model="form.bancnetTerminalOwner" data-cy="form-bancnet-terminalowner"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <!-- <v-text-field label="Bancnet Terminal Code" v-model="form.bancnetTerminalCode" data-cy="form-bancnet-terminal-code"> </v-text-field> -->
                                <v-text-field label="Bancnet Terminal ID" v-model="form.bancnetTerminalCode" data-cy="form-bancnet-terminal-code"> </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Bancnet Fixed Fee" v-model="form.bancnetFixedFee" @keypress="isNumber($event)" data-cy="form-bancnet-fixed-fee"> </v-text-field>
                            </v-flex>  
                            <v-flex xs12>
                                <v-text-field label="Bancnet Convenience Fee" v-model="form.bancnetConvenienceFee" @keypress="isNumber($event)" data-cy="form-bancnet-convenience-fee"> </v-text-field>
                            </v-flex>     
                            <v-flex xs12>
                                <v-text-field label="Maximum Amount" v-model="form.maximumAmount" @keypress="isNumber($event)" data-cy="form-maximum-amount"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12 >
                                 <v-select :items="pinEntryCapabilityList" v-model="form.pinEntryCapability" label="Pin Entry Capability" @change="getPinEntryCapability" data-cy="form-pin-entry-capability"></v-select>
                            </v-flex>      
                            <v-flex xs12 >
                                 <v-select :items="posTerminalEntryCapabilityList" v-model="form.posTerminalEntryCapability" label="POS Terminal Entry Capability" @change="getPosTerminalEntryCapability" data-cy="form-pos-terminal-entry-capability"></v-select>
                            </v-flex>     
                            <v-flex xs12 >
                                 <v-select :items="posConditionCodeList" v-model="form.posConditionCode" label="POS Condition Code" @change="getPosConditionCode" data-cy="form-pos-condition-code"></v-select>
                            </v-flex>       
                               
                            <v-flex xs12>
                                <v-text-field label="Sim Network" v-model="form.simNetwork" data-cy="form-sim-network"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Sim Card Number" v-model="form.simCardNumber" data-cy="form-simcard-number"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                 <v-menu v-model="showDateInstalledMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-date-display-installed">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="Date Installed" prepend-icon="mdi-calendar" readonly :value="displayDateInstalled" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="form.dateInstalled" no-title @input="showDateInstalledMenu = false" ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Installed By" v-model="form.installedBy" data-cy="form-installed-by"> </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Ip Address" v-model="form.ipAddress" data-cy="form-ip-address"></v-text-field>
                            </v-flex>
                            <v-flex xs12 >
                                 <v-select :items="posStatusList" v-model="form.status" label="Status" @change="getPosStatus" data-cy="form-status"></v-select>
                            </v-flex>  
                            <v-flex xs12>
                                <v-text-field label="Card Network Used" v-model="form.cardNetworkUsed" data-cy="form-card-network-used"></v-text-field>
                            </v-flex>
                            <v-flex xs12 >
                                 <v-select :items="MOTOPaymentIndicatorList" v-model="form.motopaymentIndicator" label="Motopayment Indicator" @change="getMotopaymentIndicator" data-cy="form-moto-payment-indicator"></v-select>
                            </v-flex>  
                            <v-flex xs12 >
                                 <v-select :items="unattendedAcceptanceTerminalIndicatorList" v-model="form.unattendedAcceptanceTerminalIndicator" label="Unattended Acceptance Terminal Indicator" @change="getUnattendedAcceptanceTerminalIndicator" data-cy="form-unattended-acceptance-terminal-indicator"></v-select>
                            </v-flex>  
                            <v-flex xs12 >
                                 <v-select :items="cardHolderIDMethodList" v-model="form.cardHolderidmethod" label="Card Holderidmethod" @change="getCardHolderidmethod" data-cy="form-card-holder-id-method"></v-select>
                            </v-flex> 
                            <v-flex xs12 >
                                 <v-select :items="serviceDevelopmentFieldList" v-model="form.serviceDevelopmentField" label="Service Development Field" @change="getServiceDevelopmentField" data-cy="form-service-development-field"></v-select>
                            </v-flex> 
                            <v-flex xs12 sm6 md4 >
                                <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`" data-cy="form-switch-button"></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        isNotPaymentAgent: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        branches: Array,
        deviceTypes: Array,
        users: Array,
        merchants: Array,
        paymentAgents: Array,
    },
    computed: {
        populatedForm() {
            return {
                terminalId: this.form.terminalId,
                serialNum: this.form.serialNum,
                model: this.form.model,
                type: this.form.type,
                merchant: this.form.merchant,
                branch: this.form.branch,
                deviceType: this.form.deviceType,
                paymentAgent: this.form.paymentAgent,
                user: this.form.user,
                network: this.form.network,
                location: this.form.location,
                pandateEntryMode: this.form.pandateEntryMode,
                currency: this.form.currency,
                posterminalType: this.form.posterminalType,
                bancnetAddress: this.form.bancnetAddress,
                bancnetFixedFee: this.form.bancnetFixedFee,
                bancnetMerchantSettlementBankCode: this.form.bancnetMerchantSettlementBankCode,
                bancnetTerminalOwner: this.form.bancnetTerminalOwner,
                bancnetTerminalCode: this.form.bancnetTerminalCode,
                maximumAmount: this.form.maximumAmount,
                pinEntryCapability: this.form.pinEntryCapability,
                posTerminalEntryCapability: this.form.posTerminalEntryCapability,
                posConditionCode: this.form.posConditionCode,
                bancnetConvenienceFee: this.form.bancnetConvenienceFee,
                simNetwork: this.form.simNetwork,
                simCardNumber: this.form.simCardNumber,
                dateInstalled: this.form.dateInstalled,
                installedBy: this.form.installedBy,
                ipAddress: this.form.ipAddress,
                status: this.form.status,
                cardNetworkUsed: this.form.cardNetworkUsed,
                motopaymentIndicator: this.form.motopaymentIndicator,
                unattendedAcceptanceTerminalIndicator: this.form.unattendedAcceptanceTerminalIndicator,
                cardHolderidmethod: this.form.cardHolderidmethod,
                serviceDevelopmentField: this.form.serviceDevelopmentField,
                isEnabled: this.form.isEnabled,
                formType: this.formType
            }
        },
        displayDateInstalled() {
            return this.form.dateInstalled
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        branches: {
            handler(value) {
                this.branchList = value
            }
        },
         deviceTypes: {
            handler(value) {
                this.deviceTypeList = value
            }
        },
        paymentAgents: {
            handler(value) {
                this.paymentAgentList = value
            }
        },
        users: {
            handler(value) {
                this.userList = value
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            branchList: this.branches ?? [],
            deviceTypeList: this.deviceTypes ?? [],
            paymentAgentList: this.paymentAgents ?? [],
            userList: this.users ?? [],
            merchantList: this.merchants ?? [],
            showDateInstalledMenu: false,
            currencyList: ['', 'PHP', 'USD'],
            pandateEntryModeList: ['', '00', '01', '02', '03', '04', '05', '06', '07', '90', '91', '95'],
            pinEntryCapabilityList: ['', '0', '1', '2', '8', '9'],
            posConditionCodeList: ['', '00', '01', '02', '03', '05', '06', '08', '10', '51', '59', '71'],
            posterminalTypeList: ['', '0', '1', '2', '3', '4', '5', '7'],
            posTerminalEntryCapabilityList: ['', '0', '1', '2', '3', '4', '5', '8', '9'],
            bancnetLocationList: ['', 'Luzon', 'Visayas', 'Mindanao'],
            MOTOPaymentIndicatorList: ['', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09'],
            cardHolderIDMethodList: ['', '0', '1', '2', '3', '4'],
            serviceDevelopmentFieldList: ['', '0', '1', '2', '5', '6', '7'],
            unattendedAcceptanceTerminalIndicatorList: ['', '1', '2', '3', '4'],
            typeOfPosList: ['', 'REGULAR/SALE', 'CASHOUT'],
            posStatusList: ['IN_SERVICE', 'ONLINE', 'OFFLINE', 'CLOSED', 'FOR_REPLACEMENT', 'REPLACED', 'FOR_TERMINATION', 'RETURNED', 'DEACTIVATED', 'OTHERS'],
            form: {
              branch: {},
              deviceType: {},
              paymentAgent: {},
              user: {},
              merchant: {}
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        getCurrency: async function (val) {
            this.form.currency = val
        },
        getPandateEntryMode: async function (val) {
            this.form.pandateEntryMode = val
        },
        getPinEntryCapability: async function (val) {
            this.form.pinEntryCapability = val
        },
        getPosConditionCode: async function (val) {
            this.form.posConditionCode = val
        },
        getPosTerminalType: async function (val) {
            this.form.posTerminalType = val
        },
        getPosTerminalEntryCapability: async function (val) {
            this.form.posTerminalEntryCapability = val
        },
        getLocation: async function (val) {
            this.form.location = val
        },
        getMotopaymentIndicator: async function (val) {
            this.form.motopaymentIndicator = val
        },
        getUnattendedAcceptanceTerminalIndicator: async function (val) {
            this.form.unattendedAcceptanceTerminalIndicator = val
        },
        getCardHolderidmethod: async function (val) {
            this.form.cardHolderidmethod = val
        },
        getServiceDevelopmentField: async function (val) {
            this.form.serviceDevelopmentField = val
        },
        getTypeOfPos: async function (val) {
            this.form.type = val
        },
        getPosStatus: async function (val) {
            console.log('val', val)
                this.form.status = val
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-terminal', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    terminalId: undefined,
                    serialNum: undefined,
                    model: undefined,
                    type: undefined,
                    merchant: undefined,
                    branch: undefined,
                    deviceType: undefined,
                    paymentAgent: undefined,
                    user: undefined,
                    network: undefined,
                    location: undefined,
                    pandateEntryMode: undefined,
                    currency: undefined,
                    posterminalType: undefined,
                    bancnetAddress: undefined,
                    bancnetFixedFee: undefined,
                    bancnetMerchantSettlementBankCode: undefined,
                    bancnetTerminalOwner: undefined,
                    bancnetTerminalCode: undefined,
                    maximumAmount: undefined,
                    pinEntryCapability: undefined,
                    posTerminalEntryCapability: undefined,
                    posConditionCode: undefined,
                    bancnetConvenienceFee: undefined,
                    simNetwork: undefined,
                    simCardNumber: undefined,
                    dateInstalled: undefined,
                    installedBy: undefined,
                    ipAddress: undefined,
                    status: undefined,
                    cardNetworkUsed: undefined,
                    motopaymentIndicator: undefined,
                    unattendedAcceptanceTerminalIndicator: undefined,
                    cardHolderidmethod: undefined,
                    serviceDevelopmentField: undefined,
                    isEnabled: false,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    terminalId: this.selectedItem.terminalId,
                    serialNum: this.selectedItem.serialNum,
                    model: this.selectedItem.model,
                    type: this.selectedItem.type,
                    merchant: this.selectedItem.merchant ?? {},
                    branch: this.selectedItem.branch ?? {},
                    deviceType: this.selectedItem.deviceType ?? {},
                    paymentAgent: this.selectedItem.paymentAgent ?? {},
                    user: this.selectedItem.user ?? {},
                    network: this.selectedItem.network,
                    location: this.selectedItem.location,
                    pandateEntryMode: this.selectedItem.pandateEntryMode,
                    currency: this.selectedItem.currency,
                    posterminalType: this.selectedItem.posterminalType,
                    bancnetAddress: this.selectedItem.bancnetAddress,
                    bancnetFixedFee: this.selectedItem.bancnetFixedFee,
                    bancnetMerchantSettlementBankCode: this.selectedItem.bancnetMerchantSettlementBankCode,
                    bancnetTerminalOwner: this.selectedItem.bancnetTerminalOwner,
                    bancnetTerminalCode: this.selectedItem.bancnetTerminalCode,
                    maximumAmount: this.selectedItem.maximumAmount,
                    pinEntryCapability: this.selectedItem.pinEntryCapability,
                    posTerminalEntryCapability: this.selectedItem.posTerminalEntryCapability,
                    posConditionCode: this.selectedItem.posConditionCode,
                    bancnetConvenienceFee: this.selectedItem.bancnetConvenienceFee,
                    simNetwork: this.selectedItem.simNetwork,
                    simCardNumber: this.selectedItem.simCardNumber,
                    dateInstalled: this.selectedItem.dateInstalled,
                    installedBy: this.selectedItem.installedBy,
                    ipAddress: this.selectedItem.ipAddress,
                    status: this.selectedItem.status,
                    cardNetworkUsed: this.selectedItem.cardNetworkUsed,
                    motopaymentIndicator: this.selectedItem.motopaymentIndicator,
                    unattendedAcceptanceTerminalIndicator: this.selectedItem.unattendedAcceptanceTerminalIndicator,
                    cardHolderidmethod: this.selectedItem.cardHolderidmethod,
                    serviceDevelopmentField: this.selectedItem.serviceDevelopmentField,
                    isEnabled: this.selectedItem.isEnabled,
                }
            }
        }
    }
}
</script>
